import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import SaleDetails from './pages/SaleDetails';
import Sales from './pages/Sales';
import RoyaltyReport2 from './pages/RoyaltyReport2';
import VendorSummary from './pages/VendorSummary';
import CategorySummary from './pages/CategorySummary';
import ItemSummary from './pages/ItemSummary';
import UpcSummary from './pages/UpcSummary';
import ManufacturerSummary from './pages/ManufacturerSummary';
import FranchiseSummary from './pages/FranchiseSummary';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import AccountForm from './pages/AccountForm';
import Products from './pages/Products';
import Accounts from './pages/Accounts';
import DashboardApp from './pages/DashboardApp';

// ----------------------------------------------------------------------

export default function Router() {

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'accounts', element: <Accounts /> },
        { path: 'category-summary', element: <CategorySummary /> },
        { path: 'item-summary', element: <ItemSummary /> },
        { path: 'upc-summary', element: <UpcSummary /> },
        { path: 'manufacturer-summary', element: <ManufacturerSummary /> },
        { path: 'vendor-summary', element: <VendorSummary /> },
        { path: 'royalty-report-new', element: <RoyaltyReport2 /> },
        { path: 'franchise-summary', element: <FranchiseSummary /> },
        { path: 'sale-details', element: <SaleDetails /> },
        { path: 'sale-details/:accountId/:saleId', element: <SaleDetails /> },
        { path: 'accounts/:accountId', element: <AccountForm /> },
        { path: 'sales', element: <Sales /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
