/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, MenuItem, Button } from '@mui/material';
// component
import { useApi } from '../api'

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const { get, post } = useApi();
  const [state, setState] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const { name = '', lsAccountID = 0, lsRefreshToken = '', status = 'active', posID = 0, timezoneOffset = '-4:00' } = state;
  const { accountId } = params


  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await get(`accounts/${accountId}`)
        setState(results.account);
      }
      catch (e) {
        console.log('error', e)
      }
    }

    fetchData()
  }, [accountId])

  const handleSave = async () => {
    try {
      if (accountId === 'new') {
        await post(`accounts`, state)
      } else {
        await post(`accounts/${accountId}`, state)
      }
      navigate('/dashboard/accounts')
    }
    catch (error) {
      console.log(error)
    }
  }

  console.log('state', state)

  const handleChange = (event) => {
    setState(currentState => {
      console.log(event.target.name, event.target.value)
      return {
        ...currentState,
        [event.target.name]: event.target.value
      }
    });
  };

  return (
    <Stack spacing={3}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={name}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          label="LS Account ID"
          name="lsAccountID"
          value={lsAccountID}
          onChange={handleChange}
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          fullWidth
          select
          label="Status"
          name="status"
          value={status}
          onChange={handleChange}
        >
          <MenuItem value='active'>Active</MenuItem>
          <MenuItem value='inactive'>Inactive</MenuItem>
        </TextField>

        <TextField
          fullWidth
          label="LS Refresh Token"
          name="lsRefreshToken"
          value={lsRefreshToken}
          onChange={handleChange}
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          fullWidth
          label="POS ID"
          name="posID"
          value={posID}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          label="Timezone Offset"
          name="timezoneOffset"
          value={timezoneOffset}
          onChange={handleChange}
        />
      </Stack>


      <Button fullWidth size="large" variant="contained" onClick={handleSave}>
        Save
      </Button>
      <div>
        <div>
          To get your refresh token:
          <ol>
            <li>go to: https://simplyforlife.com/ls-scripts/</li>
            <li>Click generate URL</li>
            <li>Click the link that shows up</li>
            <li>Sign in to Lightspeed</li>
            <li>Click the 'Authorize Application' button</li>
            <li>Copy the 'refresh_token' value without quotes (You'll see: "refresh_token": "4bfccb352ffcd2e1d1334dbc571" so the value is 4bfccb352ffcd2e1d1334dbc571)</li>
          </ol>
        </div>
      </div>
    </Stack>
  );
}
